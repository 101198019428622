import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSavedCards: [],
  userCashAppAccounts: [],
  approvelySavedAccounts: [],
  whiteListedStates: [],
  userSavedCardsACH: [],
  threeDFingerprinting: {},
  threeDSecureUrl: {},
};
const userSavedCardsDetails = createSlice({
  name: "usersCards",
  initialState,
  reducers: {
    setWhiteListedStates: (state, action) => {
      state.whiteListedStates = action.payload || [];
    },
    getUsersCard: (state, action) => {
      state.userSavedCards = action.payload;
    },
    getUsersAccountCashApp: (state, action) => {
      state.userCashAppAccounts = action.payload;
    },
    getUsersAccountApprovely: (state, action) => {
      state.approvelySavedAccounts = action.payload;
    },
    addUsersCard: (state, action) => {
      const card = action.payload;
      if (!card) return;
      const { userPaymentOptionId } = card;

      state.userSavedCards = state.userSavedCards.filter(
        (el) => el.userPaymentOptionId !== userPaymentOptionId
      );

      state.userSavedCards.unshift(action.payload);
    },
    deleteUserCard: (state, action) => {
      const id = action.payload;
      const deleteUser = state.userSavedCards.filter(
        (item) => item.userPaymentOptionId !== id
      );
      const deleteUserCashapp = state.userCashAppAccounts.filter(
        (item) => item.userPaymentOptionId !== id
      );
      state.userSavedCards = deleteUser;
      state.userCashAppAccounts = deleteUserCashapp;
    },
    // ach cards
    getUsersCardAch: (state, action) => {
      state.userSavedCardsACH = action.payload;
    },
    addUsersCardAch: (state, action) => {
      const card = action.payload;
      if (!card) return;
      const { userPaymentOptionId } = card;

      state.userSavedCardsACH = state.userSavedCardsACH.filter(
        (el) => el.userPaymentOptionId !== userPaymentOptionId
      );

      state.userSavedCardsACH.unshift(action.payload);
    },
    deleteUserCardAch: (state, action) => {
      const id = action.payload;
      const deleteUser = state.userSavedCardsACH.filter(
        (item) => item.userPaymentOptionId !== id
      );
      state.userSavedCardsACH = deleteUser;
    },
    setThreeDFingerprintingData(state, action) {
      const data = action.payload;
      state.threeDFingerprinting = data;
    },
    setThreeDSecureUrl: (state, action) => {
      const data = action.payload;
      state.threeDSecureUrl = data;
    },
  },
});

export const {
  getUsersCard,
  setWhiteListedStates,
  addUsersCard,
  deleteUserCard,
  getUsersCardAch,
  deleteUserCardAch,
  getUsersAccountCashApp,
  getUsersAccountApprovely
} = userSavedCardsDetails.actions;
export const userSavedCardsDetailsActions = userSavedCardsDetails.actions;
export default userSavedCardsDetails.reducer;
